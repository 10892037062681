import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { LoginButton } from './styles';
import { useAppDispatch } from 'hooks/useRedux';
import { saveFBData } from 'store/reducer/fbConnectReducer/action';

const FBConnectScreen = () => {
  const dispatch = useAppDispatch();

  const [phoneNumberId, setPhoneNumberId] = useState<string>('');
  const [wabaId, setWabaId] = useState<string>('');
  const [loginResponse, setLoginResponse] = useState<null | fb.StatusResponse>(
    null
  );

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1399648864756539', // Your Meta App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v20.0', //Graph API version
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  useEffect(() => {
    const embeddedSignupInfoListener = (event: any) => {
      if (event.origin !== 'https://www.facebook.com') return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow and the popup window closes
          if (data.event === 'FINISH' || data.event === 'FINISH_ONLY_WABA') {
            const { phone_number_id, waba_id } = data.data;
            console.log({ phone_number_id, waba_id });
            setPhoneNumberId(phone_number_id);
            setWabaId(waba_id);
            // For now, you do not need to do anything here. Once App Review has been completed, then you
            // will pass this data to your backend service and use Twilio's Senders API to register the number.
          } else {
            // otherwise the user canceled the Embedded Signup flow by closing the popup window before the last step
            // we recommend you show an error here
            const { current_step } = data.data;
            console.log(`User did not finish ESU. Last step: ${current_step}`);
          }
        }
      } catch {
        return;
      }
    };

    window.addEventListener('message', embeddedSignupInfoListener);

    return () => {
      window.removeEventListener('message', embeddedSignupInfoListener);
    };
  }, []);

  const onLogin = () => {
    window.FB.login(
      function (response) {
        // Since you are using Twilio's APIs, you do not need to do anything with the response here.
        console.log('🚀 ~ onLogin ~ response:', response);
        setLoginResponse(response);
      },
      {
        scope:
          'business_management, whatsapp_business_management, whatsapp_business_messaging',
        // config_id: '1863872187383097',
        config_id: '1725439371557621',
        auth_type: 'rerequest', // avoids "user is already logged" in errors if users click the button again before refreshing the page
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2, // required to get WABA ID
          // leave below commented for now, you will need to replace this once your app is approved by Meta
          // setup: {
          //   solutionID: SOLUTION_ID,
          // }
        },
      }
    );
  };

  const onSaveData = async () => {
    try {
      const res = await dispatch(
        saveFBData({
          phone_number_id: phoneNumberId,
          waba_id: wabaId,
          token: loginResponse?.authResponse.code || '',
          fb_login_response: loginResponse as fb.StatusResponse,
        })
      ).unwrap();
      console.log('🚀 ~ onSaveData ~ res:', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (phoneNumberId && wabaId && loginResponse) {
      onSaveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberId, wabaId, loginResponse]);

  return (
    <Box sx={{ margin: '1.2rem 3rem' }}>
      <Typography align='center' fontSize='1.6rem'>
        Whatsapp Communication
      </Typography>

      <Box sx={{ margin: '0 auto', maxWidth: '50vw' }}>
        <Typography sx={{ mt: 4, textAlign: 'center' }}>
          Login with Meta Business account to onboard Whatsapp sender in
          KollectAI platform to automate the Whatsapp Communication
        </Typography>

        <Box display='flex' justifyContent='center' mt='1rem'>
          <LoginButton onClick={onLogin}>
            <Typography>Login with Facebook</Typography>
          </LoginButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FBConnectScreen;
